<script>
import { VxWalkthruStep } from "@/components/vx/VxWalkthru";
import InstallReleaseItem from "../InstallReleaseItem";

import { useNamespace } from "@/mixins/i18n";

export default {
  name: "UpgradeStep",
  components: {
    VxWalkthruStep,
    InstallReleaseItem,
  },
  mixins: [useNamespace("settings.softwareUpdate.upgradeStep")],

  props: {
    step: {
      type: Number,
      required: true,
    },
    releases: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    showOthers: false,
  }),

  computed: {
    upgradeAvailable() {
      return this.releases.length > 0;
    },
    computedReleases() {
      return this.releases.map((rel) => {
        return {
          ...rel,
          downloadUrl: rel.url.replace(
            "https://s3.amazonaws.com/resaleai",
            "/s3"
          ),
          filename: `resaleai_v${rel.version.replace(".", "_")}.exe`,
        };
      });
    },
    recommendedUpdate() {
      return { ...this.computedReleases[0], recommended: true };
    },
    otherUpdates() {
      return this.computedReleases.slice(1);
    },
  },
};
</script>

<template>
  <VxWalkthruStep :step="step">
    <!-- title -->
    <template v-if="upgradeAvailable" #default>
      {{ i18n("title") }}
    </template>
    <template v-else #default>
      {{ i18n("titleNoUpdates") }}
    </template>
    <!-- content -->
    <template v-if="upgradeAvailable" #detail>
      <InstallReleaseItem :item="recommendedUpdate" />
      <template v-if="otherUpdates.length">
        <v-divider class="my-5" />
        <template v-if="showOthers">
          <h4 class="text-subtitle-1">Other updates</h4>
          <InstallReleaseItem
            v-for="release in otherUpdates"
            :key="release.id"
            :item="release"
          />
        </template>
        <v-btn v-else text @click="showOthers = true">
          Show other updates
        </v-btn>
      </template>

      <!-- Detail step for this release.
      <a
        :href="recommendedUpdate.downloadUrl"
        :title="recommendedUpdate.downloadUrl"
      >
        Download {{ recommendedUpdate.version }}
      </a>
      <br />
      <a
        :href="recommendedUpdate.downloadUrl"
        :title="recommendedUpdate.downloadUrl"
        target="_new"
        >Download {{ recommendedUpdate.version }} (new)</a
      >
      {{ recommendedUpdate }} -->
    </template>
  </VxWalkthruStep>
</template>
