<script>
import { format, parseISO } from "date-fns";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    version() {
      return this.item.version;
    },
    recommended() {
      return this.item.recommended;
    },
    releasedAt() {
      return format(parseISO(this.item.createdAt), "M/d/yyyy");
    },
    summary() {
      return this.item.summary;
    },
    buttonText() {
      if (this.item.recommended) {
        return "Install recommended update";
      }
      return "Install";
    },
  },

  methods: {
    onInstallClick() {
      if (!this.$raiPos) return;

      let result;
      this.$raiPos.updateVersion(this.version).then((r) => (result = r));
      console.log("updateVersion result", result);
    },
  },
};
</script>

<template>
  <v-card flat class="my-3">
    <v-card-title>
      Version {{ version }}
      <span v-if="recommended" class="ml-5 text-overline primary--text"
        >Recommended</span
      >
    </v-card-title>
    <v-card-subtitle>{{ releasedAt }}</v-card-subtitle>
    <v-card-text v-html="summary" />
    <v-card-actions>
      <v-btn
        :color="(recommended && 'primary') || 'ui'"
        outlined
        @click="onInstallClick"
        >{{ buttonText }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
